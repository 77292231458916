import PropTypes from 'prop-types';
import { useMemo, useCallback } from 'react';

import { Box, Button, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import CustomSelect from '../components/CustomSelect';
import Field from '../components/Field';
import { useParams } from 'react-router-dom';

import { useHubApplications, useApplications, useBuilds, useUpdateApplication, useBranches, useCreateApplication } from '@domatic/query';
import { useInput, useCheckbox } from '@domatic/hooks';

const _sortBranches = (a, b) => {
    if (a.name === b.name) {
        return 0;
    }
    if (['main', 'master', 'production'].includes(a.name)) {
        return -1;
    }
    if (['main', 'master', 'production'].includes(b.name)) {
        return 1;
    }
    return a.name > b.name ? 1 : -1;
};

const ApplicationDetail = ({ hubId, onCancel }) => {

    const { appId } = useParams();

    const editing = !!appId;

    const { data: hubApps } = useHubApplications(hubId);
    const application = hubApps?.find(app => app.id === appId);

    const description = useInput(application?.description, 'notEmpty', 'Description');
    const name = useInput(application?.name, 'notEmpty', 'Name');
    const branch = useInput(application?.branch, 'notEmpty', 'Branch');
    const build = useInput(application?.version, 'notEmpty', 'Version');
    const enabled = useCheckbox(application?.enabled, 'Enabled');

    const { data: apps } = useApplications();
    const { data: branches } = useBranches(name.value);
    const { data: builds } = useBuilds(name.value, branch.value);
    const { mutate: updateApplication } = useUpdateApplication();
    const { mutate: createApplication } = useCreateApplication();

    const branchList = useMemo(() => branches?.map(branch => ({
        name: branch.name,
        id: branch.name
    })).sort(_sortBranches), [branches]);

    const appList = useMemo(() => apps?.applications?.map(name => {
        return { id: name, name };
    }) ?? [], [apps]);

    const buildList = useMemo(() => builds?.map(build => {
        const branch = build.branch;
        const id = build.version;
        const name = build.version;
        const version = Number(build.version);
        return { id, name, version, branch };
    }).sort((a, b) => a.version - b.version), [builds]);

    const doReset = useCallback(() => {
        name.reset();
        description.reset();
        branch.reset();
        build.reset();
    }, [name, description, branch, build]);

    const doAddUpdate = useCallback(() => {
        if (!editing) {
            createApplication({
                hubId,
                description: description.value,
                name: name.value,
                branch: branch.value,
                version: build.value,
                enabled: enabled.checked
            }, {
                onSuccess: onCancel
            });
            return;
        }
        updateApplication({
            id: application?.id,
            description: description.value,
            name: name.value,
            branch: branch.value,
            version: build.value,
            enabled: enabled.checked
        }, {
            onSuccess: onCancel
        });
    }, [editing, updateApplication, application?.id, description.value, name.value, branch.value, build.value, enabled.checked, onCancel, createApplication, hubId]);

    const changed = (name.changed || branch.changed || build.changed || description.changed || enabled.changed);
    const valid = (name.valid && branch.valid && build.valid && description.valid);
    const enableSubmit = changed && valid;

    return (
        <Box p={2}>
            <Field field={description} required fullWidth />
            <CustomSelect field={name} data={appList} required fullWidth />
            <br />
            <br />
            <br />
            <CustomSelect field={branch} data={branchList} required fullWidth />
            <br />
            <br />
            <br />
            <CustomSelect field={build} data={buildList} required fullWidth />
            <br />
            <br />
            <br />
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={enabled.checked} onChange={enabled.onChange} name="intercom" />}
                    label="Enabled"
                />
            </FormGroup>
            <Button onClick={doAddUpdate} color='primary' disabled={!enableSubmit}>{editing ? 'Update' : 'Add'}</Button>
            &nbsp;&nbsp;
            <Button onClick={doReset} color='secondary' disabled={!enableSubmit}>Reset</Button>
        </Box >
    );
};

ApplicationDetail.propTypes = {
    hubId: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired
};
export default ApplicationDetail;
